import { createRouter, createWebHistory } from 'vue-router';
import Store from '@/store/index';

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'index',
            component: () => import('../views/layout/index.vue'),
            children: [
                {
                    path: '/',
                    name: 'home-index',
                    component: () => import('../views/index/index.vue')
                },
                {
                    path: 'user/',
                    name: 'user',
                    component: () => import('../views/user/index.vue'),
                    redirect: 'user/info',
                    children: [
                        {
                            path: 'info',
                            name: 'user-info',
                            component: () => import('../views/user/info.vue'),
                        },
                        {
                            path: 'order',
                            name: 'user-order',
                            component: () => import('../views/user/order.vue'),
                        },
                        {
                            path: 'order/detail/:id',
                            name: 'user-order-detail',
                            component: () => import('../views/user/orderDetail.vue'),
                        },
                        {
                            path: 'collection',
                            name: 'user-collection',
                            component: () => import('../views/user/collection.vue'),
                        },
                        {
                            path: 'gold',
                            name: 'user-gold',
                            component: () => import('../views/user/gold.vue'),
                        },
                        {
                            path: 'news',
                            name: 'user-news',
                            component: () => import('../views/user/news.vue'),
                        },
                        {
                            path: 'news/detail/:id',
                            name: 'user-news-detail',
                            component: () => import('../views/user/newsDetail.vue'),
                        },
                        {
                            path: 'task/:id',
                            name: 'user-task',
                            component: () => import('../views/user/task.vue'),
                        },
                        {
                            path: 'evaluate/:id',
                            name: 'user-evaluate',
                            component: () => import('../views/user/evaluate.vue'),
                        }
                    ]
                },
                {
                    path: 'user/middle',
                    name: 'user-middle',
                    component: () => import('../views/user/middle.vue'),
                },
                {
                    path: 'product/list',
                    name: 'product-list',
                    component: () => import('../views/product/list.vue')
                },
                {
                    path: 'product/detail/:id',
                    name: 'product-detail',
                    component: () => import('../views/product/detail.vue')
                },
            ]
        },
        {
            path: '/pdf',
            name: 'pdf-index',
            component: () => import('../views/pdf/index.vue')
        },
        {
            path: '/ceshi',
            name: 'ceshi',
            component: () => import('../views/ceshi.vue')
        }
    ],
    scrollBehavior () {
        return { top: 0 };
    }
});


router.beforeEach((to, from) => {
    // console.log(to, 'to');
    // console.log(from, 'from');
    // 关闭留言窗口
    if (Store.state.common.isShowLeaveMessageModel) {
        Store.commit('common/changeLeaveMessageModel', false);
    }
    
    // 未登录，不给跳转到用户中心
    if (to.name.indexOf('user-') !== -1 && !Store.state.login.isLogin && from.name !== undefined) {
        router.push({ path: from.path });
        return false;
    }
});

export default router;
