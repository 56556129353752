// 中文
export default {
    commont: {
        groupScheduleTip1: 'The course period has been reserved',
        lhq: 'The current browser does not support session storage. Please do not use the traceless mode',
        age: {
            a1: '3-5 years old',
            a2: '6-12 years old',
            a3: '13-17 years old',
            a4: '18 years old or above',
        },
        date: {
            d1: 'Weekdays: Monday to Friday',
            d2: 'Weekend: Saturday and Sunday'
        },
        time: {
            t1: 'Before noon 12:00 p.m.',
            t2: 'Afternoon 12:00-18:00 p.m.',
            t3: 'Evening 18:00-24:00 p.m.'
        },
        scheduleModel: {
            title: 'Lesson time',
            cs1: 'Book a lesson ',
            cs2: 'Show all schedules',
            cs3: 'Total',
            cs4: 'Lesson',
            cs5: 'Only left',
            cs6: 'Quota'
        }
    },
    productItem: {
        nianling: 'Age',
        fen: 'minute'
    },
    week: {
        zhouyi: 'Monday',
        zhouer: 'Tuesday',
        zhousan: 'Wednesday',
        zhousi: 'Thursday',
        zhouwu: 'Friday',
        zhouliu: 'Saturday',
        zhouri: 'Sunday',
    },
    day: {
        shangwu: 'Morning',
        zhongwu: 'Noon',
        xiawu: 'Afternoon',
        wanshang: 'Evening',
        shenye: 'Late night'
    },
    header: {
        login: 'Login',
        search: 'Search',
        moreLanguange: 'Multilingual',
        user: 'Personal Center',
        tuichu: 'Sign Out',
        tongzhi: 'Notification'
    },
    // 登录弹窗
    loginModel: {
        youxiang: 'Email',
        dengru: 'Login',
        mima: 'Password',
        querenmima: 'Confirm Password',
        yanzhengma: 'Verification Code',
        wangjimima: 'Forgot password',
        zhuce: 'Register',
        shangweizhuce: 'Not registered yet?',
        chongzhimima: 'Reset password',
        fasong: 'Send out',
        queding: 'Confirm',
        xingming: 'Name',
        tijiao: 'Submit'
    },
    // 留言弹窗
    lmModle: {
        title: 'Enquiry',
        fi1: 'Contact information',
        fi1p: 'Enter your contact information/number',
        fi2p: 'Please leave a message, and we will get back to you soon',
        btn: 'Send'
    },
    // ai弹窗
    ai: {
        t1: 'Specify your needs',
        t2: 'AI will recommend the most suitable lesson to you in 30 seconds!',
        kaishi: 'Get started',
        yuyan1: 'What language do you want to learn？',
        yuyan2: 'Tell us what language you would like to learn',
        kecheng: 'What courses do you want to study？',
        dengji: 'Choose the option that fit you most.',
        age1: 'What is your age？',
        age2: 'Please mark the corresponding box',
        week: 'Which day of the week do you prefer？',
        time: 'What time of the day do you prefer？',
        shangyibu: 'Previous',
        xiayibu: 'Next',
        seekText1: 'Searching the right course for you',
        seekText2: 'Getting ready to jump to the search page'
    },
    // 首页
    index: {
        banner_t1: 'Let you learn freely',
        banner_t2: 'Free your curiosity with different lessons and groups on various topics you can imagine.',
        banner_t3: 'Browse the courses',
        shousuogengduokecheng: 'Search for more courses',
        reason_title: '4 Reasons for choosing MRS education',
        reason_btn: 'Start choosing your teacher!',
        product_title: 'Start your high-quality course journey here and now',
        wonder_title: 'About us',
        gongsi_title: 'Company evaluation'
    },
    // 底部
    footer: {
        t1: 'Join our MRS family and become a MRS teacher',
        t2: 'Copyright © 2022 MRS Education. All rights reserved.',
        t3: 'Please send your name, contact information, resume, your self-introduction with a YouTube video link and other information to our official email: mrs-edu@yandex.com',
        t4: 'Become a teacher',
        t5: 'Confirm',
        t6: 'Click the email button to send with attachments',
        t7: 'Contact us'
    },
    pdf: {
        shang: 'Prev',
        xia: 'Next',
        da: 'Enlarge',
        xiao: 'Zoom Out',
        t1: 'The current courseware does not exist.'
    },
    // 个人中心
    user: {
        nodata: 'No Data',
        fanhui: 'Go Back',
        index: {
            xiugaimima: 'Change Password',
            n1: 'Basic Information',
            n2: 'My Coins',
            n3: 'My Order',
            n4: 'My Collection',
            n5: 'My Message'
        },
        info: {
            title: 'Personal Information',
            xingming: 'Name',
            touxiang: 'Your photo',
            xingbie: 'Gender',
            nan: 'Male',
            nv: 'Female',
            nianling: 'Age',
            youxiang: 'Email Address',
            baocun: 'Save',
            wechat: 'WeChat ID',
            whatsapp: 'What’s App ID'
        },
        collection: {
            title: 'My Collection',
            quxiao: 'Cancel'
        },
        gold: {
            title: 'My Coins',
            shengyujinbi: 'Remaining Coins',
            chongzhi: 'Recharge',
            zixunkefu: 'Customer Services',
            liushui: 'Billing Details',
            jinbi: 'Coins',
            xiaofei: 'Consumption',
            saomazhifu: 'Scan QR code or click the link to pay',
            sm: 'Descriptions：',
            cz1: '1、1 coin can be converted into 1 RMB/HKD/USD according to different courses and regions;',
            cz1_2: '2、The coins displayed for the same course may be different in different regions;',
            cz2: 'Recharge Amount:',
            cz3: 'Recharge an integer for the coins',
            cz4: 'Payment Method:',
            cz5: 'Payment Amount:',
            cz6: 'After scanning the code and paying, please contact the customer service or leave a message in time, and the staff will deal with it as soon as possible.',
            zhifu: 'Payment'
        },
        order: {
            title: 'My Order',
            n1: 'All',
            n2: 'To be paid',
            n3: 'Paid',
            dingdanhao: 'Order Number：',
            dingdanshijian: 'Order Time:',
            yizhifu: 'Paid',
            daizhifu: 'To be paid',
            qushangke: 'Go to lesson',
            kejian: 'Courseware',
            zuoye: 'Homework',
            pingjia: 'Evaluation',
            quzhifu: 'Go to Pay your course',
            xiangqing: 'Order details',
            shangkeshijian: 'Lesson time:',
            f1: 'Link copied successfully, please open it in browser!',
            f2: 'This browser does not support automatic copying',
            b1: 'The current courseware does not exist',
            detail: {
                d1: 'Time before your lesson starts: ',
                d2: 'Lesson',
                d3: 'Lesson Time',
                d4: 'Operation',
            }
        },
        news: {
            title: 'My Message',
            n1: 'All',
            n2: 'Read',
            n3: 'Unread',
            detailTitle: 'Message',
            detailTitle1: 'Admin/Teacher Reply'
        },
        evaluate: {
            kechengpingjia: 'Course Evaluation',
            laoshipingjia: 'Teacher Evaluation',
            k1: 'State your opinion of the course content',
            k2: 'Evaluate your teacher',
            tijiao: 'Submit',
            pj: 'Evaluation'
        },
        task: {
            t1: 'Homework File',
            zuoye: 'Homework',
            shangchuan: 'Upload',
            shuruneirong: 'Input Content',
            tijiao: 'Send Out',
            d1: 'No.',
            d2: 'Lesson'
        }
    },
    // 列表
    list: {
        title: 'Find the lesson',
        searchType: 'Type',
        searchTag: 'Tag',
        searchTime: 'Time',
        searchAge: 'Age',
        sousuo: 'Search',
        chongzhi: 'Reset',
        liulangengduo: 'Browse More',
        shijianyi: 'Lesson time is measured by minutes',
        xianshi: 'Display',
        pinglun: 'Evaluations',
        sui: 'Age',
        jie: 'Class',
        caozuochenggong: 'Operation succeeded'
    },
    // 详情
    detail: {
        p1: 'This course has a total of',
        p2: 'Number of comments',
        t1: 'View course timetable',
        shoucang: 'Collection',
        fenxiang: 'Share',
        t2: 'Group Lessons',
        t3: 'One-to-one Lessons',
        i1: 'Minute',
        i2: 'Per class',
        i3: 'Once a week',
        i4: 'Total',
        i5: 'Class',
        i6: 'Age',
        i7: 'Number of learners per class',
        i8: 'Minimum',
        z1: 'Self-introduction',
        z2: 'Browse all self-introductions',
        z3: 'Video Introduction',
        z4: 'Teacher evaluation',
        z5: 'Show all evaluations',
        wenti: {
            t: 'Common questions',
            b1: 'Appointment method',
            c11: 'After purchasing a course, you can make an appointment according to the teacher’s calendar',
            c12: 'If the teacher displays "Real time teaching available", he/she can be booked for a lesson immediately, this item is only suitable for English speaking class',
            c13: 'If the admin/teacher is not online, the teacher can be booked for a lesson after 24 hours',
            b2: 'Fees Description',
            c21: 'The trial/assessment course lasts about 30 minutes',
            c22: 'The formal course lasts about 30 or 45 minutes',
            c23: 'There is an extra discount for purchasing 20 or more formal courses (depending on the teacher’s course price)',
            b3: 'Courses Description',
            c31: 'Enter the website 10 minutes before the class starts, select My Course to find the class and click Enter Classroom to have the class. Please click the buttons to connect the audio and turn on the camera',
            c32: 'Smartphone, pad, and computer can be used in MRS courses (please download the application first).',
            b4: 'Withdrawal reminders',
            c41: 'When a problem occurs in the course, you can select "Report Problem" and the admin/teacher can choose to reschedule a new appointment or refund the course coins for future usage.',
            c42: 'Our admin/teacher will reply within 24 hours for the solution, if the problem happens during the busy hours, it may take 2-3 working days'
        },
        bp1: 'Course evaluation',
        bp2: 'Show all reviews',
        x1: 'Similar teachers’ recommendation',
        orderTip: 'Choose the teaching time to purchase corresponding courses',
        copyTip1: 'Successfully copied, please open it in the browser!',
        copyTip2: 'This browser does not support automatic copying',
        noDeatilDataTip: 'No detailed data currently available'
    },
    // 校验提示
    checkTip: {
        // 留言弹窗
        lmm: {
            t1: 'Please enter contact information',
            t2: 'Please enter your message',
            t3: 'Message successfully sent!'
        },
        // 登录窗口
        loginModel: {
            youxiang: 'The email address must be filled',
            mima: 'The password must be filled ',
            querenmima: 'Re-confirm your password',
            mimayiyang: 'Two entered passwords are different',
            yanzhengma: 'The verification code must be filled',
            xingming: 'Your name must be filled',
            dengluchenggong: 'Login successful',
            xiugaimimachenggong: 'Password modified successfully!',
            zhucechenggong: 'Registration succeeded!',
            fasongyanzhengma: 'The verification code has been sent to the email address. Please check!',
            tuichudenglu: 'Are you sure to log out?',
            queding: 'Sure',
            tuichuchenggong: 'Log out successfully'
        },
        // 个人中心-评价
        evaluate: {
            kecheng: 'State your opinion of the course content',
            laoshi: 'Evaluate your teacher',
            t1: 'The evaluation of the course',
            t2: ' The evaluation of the course should not be less than 10 words',
            t3: ' The evaluation of the teacher ',
            t4: " The evaluation of the teacher should not be less than 10 words",
            success: 'Evaluation finished!'
        },
        userinfo: {
            xingming: 'Please enter your name',
            nianling: 'Please enter your age',
            tupiandaxiao: 'The size of the uploaded image cannot exceed',
            tupianshibai: 'Upload failed!',
            gengxinchenggong: 'Update succeeded!',
            wechat: 'Please enter the WeChat account',
            whatsapp: 'Please enter the WhatsApp account'
        },
        // 个人中心-作业
        usertask: {
            nr: 'Please enter the description of document/homework',
            tupiandaxiao: 'The size of the uploaded file cannot exceed',
            tupianshibai: 'Upload failed!'
        },
        // 下单
        orderModel: {
            time1: 'The new class time must be longer than the last class time',
            time2: 'Please recharge the remaining class time of the current package',
            tianjia: 'The current class time has been added',
            xuanzetaocan: 'Please select a package',
            tip1: 'The remaining coins in the current account are not enough to pay for the selected package, do you want to jump to the coins recharge page now',
            queding: 'Confirm',
            quxiao: 'Cancel',
            success: ' The course has been successfully purchased'
        },
        // 金币
        gold: {
            t1: 'Please select payment method'
        }
    }
}