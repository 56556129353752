// state
const state = () => ({
    isShowAiModel: false,
    isShowLeaveMessageModel: false,
    // 课程详情页老师id
    courseDetailTeacherId: 0,
    // 显示下单窗口
    isShowPlaceOrderModel: false,
    // 下单选中课程时间表数据
    placeOrderScheduleObj: {},
    // 是否移动窗口状态
    isMobileWindowType: false,
    // 头部样式
    layoutHeaderClass: ''
});

// actions
const actions = {
};

// mutations
const mutations = {
    // 修改header定位
    changeHeaderClass (state, value) {
        if (value === 'home-index' || value === 'product-list') {
            state.layoutHeaderClass = 'fixed-layout-header';
        } else {
            state.layoutHeaderClass = 'default-layout-header';
        }
    },
    // 修改ai窗口
    changeAiModel (state, value) {
        state.isShowAiModel = value;
    },
    // 修改留言窗口
    changeLeaveMessageModel (state, value) {
        state.isShowLeaveMessageModel = value;
    },
    // 修改课程详情页老师id
    changeDetailTeacherId (state, value) {
        state.courseDetailTeacherId = value;
    },
    // 修改下单窗口
    changePlaceOrderModel (state, value) {
        state.isShowPlaceOrderModel = value;
    },
    // 下单选中课程时间表数据
    changePlaceOrderSchedule (state, data) {
        state.placeOrderScheduleObj = data;
    },
    // 修改移动窗口状态
    changeMobileWindowType (state, data) {
        state.isMobileWindowType = data
    }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
}