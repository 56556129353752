<template>
    <div id="app">
        <router-view v-if="isRouteActive"></router-view>
    </div>  
</template>

<script>


import { provide, ref, nextTick } from 'vue'

export default {
    name: 'App',
    setup () {
        const isRouteActive = ref(true);
        provide('reload', ()=>{
            isRouteActive.value = false;
            nextTick(()=>{
                isRouteActive.value = true;
            });
        });

        return {
            isRouteActive
        }
    }
}
</script>

<style lang="less">
html {
    font-size: 62.5%;
}

@media screen and (max-width: 1024px) {
    html {
        font-size: 40%;
    }
}

body {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: 1.8rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}

* {
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

a {
    text-decoration: none;
}

ul {
    li {
        list-style: none;
    }
}

.mclass {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.c80 {
    color: #808080!important;
}
.cFF5C33 {
    color: #FF5C33!important;
}

.el-select__popper.el-popper, .el-message,.el-loading-mask, .el-overlay, .el-tooltip__trigger, .el-picker__popper {
    z-index: 999999999999!important;
}

.el-message-confirm-btn, .el-button--primary {
    border: 0!important;
    background: #70c8dd!important;

    &:focus-visible {
        outline: none!important;
    }
    &:hover {
        background: #44c1e0!important;
    }
}

.user-info-box {
    .el-upload {
        width: 6rem;
        height: 6rem;
        
        .avatar-uploader-icon {
            font-size: 14px;
            color: #8c939d;
            width: 3rem;
            height: 3rem;
            text-align: center;
        }
    }
}

.fixed-right-model {
    position: fixed;
    right: 0;
    bottom: 30%;
    z-index: 9999;

    .lm-i {
        width: 5rem;
        height: 5rem;
        min-width: 40px;
        min-height: 40px;
        cursor: pointer;
        background: #70c8dd;
        border-radius: 5px 0 0 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #44c1e0;
        }

        img {
            display: block;
            max-width: 4.2rem;
            max-height: 4.2rem;
            min-width: 34px;
            min-height: 34px;
        }
    }
}
.user-drawer-model {
    .el-drawer__header {
        margin-bottom: 0!important;
    }
}
</style>
