import { ElMessageBox, ElMessage } from 'element-plus';
import { writeSessionData, getSessionData, clearSessionData, handleTokenErrorLink } from '@/lib/config';
import { TOKEN, USER_INFO } from '@/lib/sessionStorageAttr';
import { loginSignOut, userInfo } from '@/api/list';
import VueI18n from '@/languange/index';

// state
const state = () => ({
    // 显示弹窗
    isShowModel: false,
    // 登陆状态
    loginStatus: 'login',
    // 登陆状态
    isLogin: false,
    // token
    lgToken: '',
    // 用户信息
    userInfo: {},
    // 金币
    coin: 0
});

// actions
const actions = {
    // 初始化登陆处理
    handleInitLogin ({ commit, dispatch }) {
        const historyToken = getSessionData({ module: TOKEN });
        if (historyToken !== null) {
            commit('changeToken', historyToken);
            commit('changeLoginStatus', true);
            dispatch('getUserInfo');
        }
    },
    // 获取用户信息
    getUserInfo ({ commit }) {
        userInfo().then(res => {
            commit('changeUserInfo', {
                email: res.email,
                id: res.id,
                name: res.name,
                avatar: res.avatar
            });
            commit('changeCoin', res.coin);
        });
    },
    // 登陆
    signLogin ({ commit }) {
        commit('changeLoginStatus', true);
    },
    // 退出登录
    signOut ({ dispatch }) {
        ElMessageBox.alert(VueI18n.global.t('checkTip.loginModel.tuichudenglu'), {
            confirmButtonText: VueI18n.global.t('checkTip.loginModel.queding'),
            confirmButtonClass: 'el-message-confirm-btn',
            callback: (action) => {
                // 确认
                if (action=== 'confirm') {
                    loginSignOut().then(() => {
                        dispatch('handleCzSignOutFn');
                        ElMessage({ message: VueI18n.global.t('checkTip.loginModel.tuichuchenggong'), type: 'success' });
                    });
                }
            }
        });
    },
    // 处理操作退出登录
    handleCzSignOutFn ({ commit }) {
        commit('changeToken', '');
        commit('changeLoginStatus', false);
        commit('changeUserInfo', {});
        commit('changeCoin', 0);
        // 当在个人中心时，跳转到首页
        handleTokenErrorLink();
    }
};

// mutations
const mutations = {
    // 修改登陆状态
    changeLoginStatus (state, value) {
        state.isLogin = value;
    },
    // 修改登录窗口
    changeMoldeShow (state, value) {
        state.isShowModel = value;
    },
    // 修改状态值
    changeMoldeLoginStatus (state, value) {
        state.loginStatus = value;
    },
    // 修改token
    changeToken (state, value) {
        if (value !== '') {
            writeSessionData({ module: TOKEN, data: value });
        } else {
            clearSessionData({ module: TOKEN });
        }
        state.lgToken = value;
    },
    // 修改用户信息
    changeUserInfo (state, value) {
        if (Object.keys(value).length > 0) {
            writeSessionData({ module: USER_INFO, data: value });
        } else {
            clearSessionData({ module: USER_INFO });
        }
        state.userInfo = value;
    },
    // 修改金币
    changeCoin (state, value) {
        state.coin = value;
    }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
}