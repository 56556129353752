import { axionsPost, axionsGet } from './api.js';

// loadingType加载状态，默认加载，页面调用传false，则不加载

// 登陆
export const loginSignIn = (data, loadingType) => axionsPost('auth/account-login', data, loadingType);

// 注册
export const loginRegister = (data, loadingType) => axionsPost('auth/account-register', data, loadingType);
// 邮件注册码
export const loginEmailCode = (data, loadingType) => axionsPost('auth/email-code', data, loadingType);
// 登陆验证码
export const loginGetCaptcha = (data, loadingType) => axionsGet('auth/get-captcha', data, loadingType);
// 登出
export const loginSignOut = (data, loadingType) => axionsGet('user-center/login-out', data, loadingType);

// 用户修改密码
export const userUpdatePassword = (data, loadingType) => axionsPost('auth/update-password', data, loadingType);

// 用户信息
export const userInfo = (data, loadingType) => axionsGet('user-center/user-info', data, loadingType);
// 用户信息更新
export const userInfoUpdate = (data, loadingType) => axionsPost('user-center/update-user-info', data, loadingType);

// 金币流水
export const getCoinLogList = (data, loadingType) => axionsGet('user-center/get-coin-log-list', data, loadingType);

// 获取留言列表
export const getMessageList = (data, loadingType) => axionsGet('user-center/get-message-list', data, loadingType);
// 发送留言
export const sendMessage = (data, loadingType) => axionsPost('base/send-message', data, loadingType);
// 消息已读
export const readMessage = (data, loadingType) => axionsPost('user-center/read-message', data, loadingType);

// 收藏列表
export const getFavourList = (data, loadingType) => axionsGet('user-center/get-favour-list', data, loadingType);

// 收藏课程
export const favourLesson = (data, loadingType) => axionsPost('lesson/favour-lesson', data, loadingType);

// 课程评论列表
export const lessonCommentList = (data, loadingType) => axionsGet('lesson/get-lesson-comment-list', data, loadingType);
// 课程类型列表
export const lessonTypeList = (data, loadingType) => axionsGet('lesson/get-type-list', data, loadingType);
// 课程标签列表
export const lessonTagList = (data, loadingType) => axionsGet('lesson/get-tag-list', data, loadingType);

// 课程列表接口
export const getLessonList = (data, loadingType) => axionsGet('lesson/get-lesson-list', data, loadingType);

// 课程详情
export const getLessonDetail = (data, loadingType) => axionsGet('lesson/get-lesson-detail', data, loadingType);

// 课程时间表
export const getLessonSchedule = (data, loadingType) => axionsGet('lesson/get-lesson-schedule', data, loadingType);

// 老师评论列表
export const teacherCommentList = (data, loadingType) => axionsGet('teacher/get-teacher-comment-list', data, loadingType);

// ai配置
export const aiConfig = (data, loadingType) => axionsGet('ai/base', data, loadingType);

// 首页
export const getAdConfig = (data, loadingType) => axionsGet('base/get-ad-setting', data, loadingType);

// 上传
export const postUpload = (data, loadingType) => axionsPost('upload', data, loadingType);

// 下单
export const placeOrder = (data, loadingType) => axionsPost('user-center/place-order', data, loadingType);

// 订单列表
export const orderList = (data, loadingType) => axionsGet('user-center/order-list', data, loadingType);

// 订单详情
export const orderDetail = (data, loadingType) => axionsGet('user-center/order-detail', data, loadingType);

// 获取课件
export const getBook = (data, loadingType) => axionsGet('study/get-book', data, loadingType);

// 获取上课链接
export const getLessonUrl = (data, loadingType) => axionsGet('study/get-lesson-url', data, loadingType);

// 获取作业
export const getHomework = (data, loadingType) => axionsGet('study/get-homework', data, loadingType);

// 提交作业
export const uploadHomework = (data, loadingType) => axionsPost('study/upload-homework', data, loadingType);

// 提交评教
export const postComment = (data, loadingType) => axionsPost('lesson/comment', data, loadingType);

// 获取标题和描述
export const getAdTitle = (data, loadingType) => axionsGet('base/get-ad-title', data, loadingType);
