import { createStore, createLogger } from 'vuex';
import login from './modules/login';
import common from './modules/common';

const debug = process.env.NODE_ENV !== 'production';

export default createStore({
    modules: {
        login,
        common
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});