import { LANGUANGE } from "@/lib/sessionStorageAttr";
// 缓存数据
const writeSessionData = (params) => {
    let { module, key, data } = params;
    let cacheData = {};
    try {
        cacheData = JSON.parse(sessionStorage.getItem(module));
    } catch (e) {
        alert('当前浏览器不支持sessionStorage,请勿使用无痕模式');
    }
    if (key === undefined) {
        sessionStorage.setItem(module, JSON.stringify(data));
    } else {
        Object.assign(cacheData[key], data);
        sessionStorage.setItem(module, JSON.stringify(cacheData));
    }
};

// 获取缓存
const getSessionData = (params) => {
    let { module, key } = params;
    let cacheData;
    try {
        cacheData = JSON.parse(sessionStorage.getItem(module));
    } catch (e) {
        alert('当前浏览器不支持sessionStorage,请勿使用无痕模式');
    }
    if (key === undefined) {
        return cacheData;
    } else {
        return cacheData[key];
    }
};

// 清除缓存
const clearSessionData = (params) => {
    // 清除单个session
    if (params) {
        let { module } = params;
        sessionStorage.removeItem(module);
        return;
    }
    // 清除所有
    sessionStorage.clear();
};

// 处理历史缓存语言（设置set，获取get，清理clear）
const handleHistroyLanguange = (type, data) => {
    if (type === 'get') {
        //用户指定了默认语言时，使用用户指定的
        if (getSessionData({ module: LANGUANGE }) != null) {
            return getSessionData({ module: LANGUANGE });
        } else {
            const language = navigator.language;
            //用户未指定时，根据游览器选择:
            if (language === 'zh-CN') {
                return 'zh_cn';
            } else if (language === 'zh-TW') {
                return 'zh_tw';
            } else if (language === 'en') {
                return 'en';
            } else {
                return 'en';
            }
        }
    }

    if (type === 'set') {
        writeSessionData({ module: LANGUANGE, data: data });
        return;
    }

    clearSessionData({ module: LANGUANGE });
};
export {
    handleHistroyLanguange
}
