/* 
 *sessionStorage变量存放
 *
 */

// 语言
const LANGUANGE = 'xk_lg';

// 登录token
const TOKEN = 'xk_tn';

// 用户个人信息
const USER_INFO = 'xk_us_i';

// 消息详情
const MESSAGE_DETAIL = 'xk_m_d';

// ai搜索缓存
const AI_SEARCH_HISTORRY = 'xk_a_s';

export {
    LANGUANGE,
    TOKEN,
    USER_INFO,
    MESSAGE_DETAIL,
    AI_SEARCH_HISTORRY
};
