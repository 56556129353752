import { createI18n } from "vue-i18n";
import { handleHistroyLanguange } from "@/lib/fn";

let messages = {};

// 获取所有module里默认值值
const modules = require.context('./modules', true, /\.js$/);
const getMessage = (langs) => {
    langs.keys().forEach((key) => {
        const name = key.replace(/\.\/(.*)\.js/, '$1');
        messages[name] = langs(key).default;
    });
};
getMessage(modules);

// 获取缓存语言值
const localeHistory = handleHistroyLanguange('get');

const i18n = createI18n({
    legacy: false,
    locale: localeHistory,
    silentFallbackWarn: true,
    messages
});

export default i18n;
