// webpack构建变量
const proEnv = process.env.NODE_ENV;

// 接口api域名
let apiPrefix = '';
// 图片上传域名
let uploadApiPrefix = '';

// 配置
const apiConfig = {
    // 生产
    production: {
        api: '//lesson-api.c-hiang.cn/api/'
    },
    // 开发
    dev: {
        api: 'https://lesson-api.c-hiang.cn/api/'
        // api: 'http://dev.lesson.com/api/',
    }
};

// 生产域名
if (proEnv === 'production') {
    apiPrefix = apiConfig['production']['api'];
    uploadApiPrefix = apiConfig['production']['uploadApi'];
} else if (proEnv === 'testing') {
    // 测试环境
    apiPrefix = apiConfig['test']['api'];
    uploadApiPrefix = apiConfig['test']['uploadApi'];
} else {
    // 开发模式
    apiPrefix = apiConfig['dev']['api'];
    uploadApiPrefix = apiConfig['dev']['uploadApi'];
}

export {
    apiPrefix,
    uploadApiPrefix
};
