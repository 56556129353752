import axios from 'axios';
import { apiPrefix } from './domain';
import { getSessionData } from '@/lib/config.js';
import { handleHistroyLanguange } from '@/lib/fn.js';
import { ElMessage, ElLoading } from 'element-plus';
import Store from '@/store/index';

// 缓存变量
import { TOKEN } from '@/lib/sessionStorageAttr.js';


// 头部设置值
let headerObj = {};

// 请求头部处理
axios.defaults.headers = headerObj;

// 请求拦截器 例
axios.interceptors.request.use(
    config => {
        // console.log(config, 'config');
        // 判断是否初始化添加过token
        const _token = getSessionData({ module: TOKEN });
        if (_token !== null && _token !== '') {
            config.headers.token = _token;
        }

        config.headers.locale = handleHistroyLanguange('get');

        return config;
    },
    err => {
        return Promise.reject(err);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    function (response) {
        // console.log(response, 'response');
        // 登录失效，提示重新登录
        if (response.data.code === '10001' || response.data.code === 10001) {
            // 退出登录
            ElMessage({ message: response.data.msg, type: 'error' });
            Store.dispatch('login/handleCzSignOutFn');
        } else {
            return Promise.resolve(response);
        }
    },
    function (error) {
        return Promise.reject(error);
    }
);

// post请求
const axionsPost = (url, params, loadingType) => {
    if (Object.prototype.toString.call(params) !== '[object FormData]') {
        return new Promise((resolve, reject) => {
            const loading = loadingType === undefined ? ElLoading.service({ fullscreen: true }) : null;

            // axios.post(apiPrefix + url, Object.assign({ locale: handleHistroyLanguange('get') }, params))
            axios.post(apiPrefix + url, params)
            .then(res => {
                if (loading !== null) {
                    loading.close();
                }
                if (res.data.code !== 0) {
                    ElMessage({ message: res.data.msg, type: 'warning' });
                    reject();
                } else {
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                if (loading !== null) {
                    loading.close();
                }
                reject(err);
            });
        });
    } else {
        return new Promise((resolve, reject) => {
            // 请求参数为formdata 的请求
            const instance = axios.create({});
            instance.defaults.headers = {
                token: getSessionData({ module: 'TOKEN' })
            };
            instance.post(apiPrefix + url, params)
            .then(res => {
                if (res.data.code !== 0) {
                    ElMessage({ message: res.data.msg, type: 'error' });
                    reject();
                } else {
                    resolve(res.data.data);
                }
            })
            .catch(err => {
                reject(err);
            });
        });
        
    }
};

// 不显示加载动画url数组
const isNoLoadUrl = ['auth/get-captcha', 'ai/base', 'user-center/read-message', 'lesson/get-lesson-schedule'];

// get请求，接受参数为{ params: {}, successCb () => {}, errorCb () => {} }
const axionsGet = (url, params, loadingType) => {
    return new Promise((resolve, reject) => {
        if (isNoLoadUrl.indexOf(url) !== -1) {
            loadingType = false;
        }

        const loading = (loadingType === undefined || loadingType) ? ElLoading.service({ fullscreen: true }) : null;

        axios.get(apiPrefix + url, { params: params })
        .then(res => {

            if (loading !== null) {
                loading.close();
            }

            if (res.data.code !== 0) {
                ElMessage({ message: res.data.msg, type: 'warning' });
                reject();
            } else {
                resolve(res.data.data);
            }
        })
        .catch(err => {
            if (loading !== null) {
                loading.close();
            }
            reject(err);
        });
    });
};

export {
    axionsPost,
    axionsGet
};