import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './languange';
import { ElMessage } from 'element-plus';
import 'font-awesome/css/font-awesome.min.css';
import 'element-plus/es/components/message-box/style/css';
import 'element-plus/es/components/message/style/css';
import 'element-plus/es/components/loading/style/css';

const app = createApp(App);

app.use(store).use(i18n).use(ElMessage).use(router).mount('#app');
