import { MESSAGE_DETAIL, AI_SEARCH_HISTORRY } from "@/lib/sessionStorageAttr";
import Router from '@/router/index';
import VueI18n from '@/languange/index';

// 缓存数据
const writeSessionData = (params) => {
    let { module, key, data } = params;
    let cacheData = {};
    try {
        cacheData = JSON.parse(sessionStorage.getItem(module));
    } catch (e) {
        alert(VueI18n.global.t('commont.lhq'));
    }
    if (key === undefined) {
        sessionStorage.setItem(module, JSON.stringify(data));
    } else {
        Object.assign(cacheData[key], data);
        sessionStorage.setItem(module, JSON.stringify(cacheData));
    }
};

// 获取缓存
const getSessionData = (params) => {
    let { module, key } = params;
    let cacheData;
    try {
        cacheData = JSON.parse(sessionStorage.getItem(module));
    } catch (e) {
        alert(VueI18n.global.t('commont.lhq'));
    }
    if (key === undefined) {
        return cacheData;
    } else {
        return cacheData[key];
    }
};

// 清除缓存
const clearSessionData = (params) => {
    // 清除单个session
    if (params) {
        let { module } = params;
        sessionStorage.removeItem(module);
        return;
    }
    // 清除所有
    sessionStorage.clear();
};

const configOptions = {
    // 年龄配置
    age: [
        { cvalue: '3-5', show: VueI18n.global.t('commont.age.a1'), iden: 1 },
        { cvalue: '6-12', show: VueI18n.global.t('commont.age.a2'), iden: 2 },
        { cvalue: '13-17', show: VueI18n.global.t('commont.age.a3'), iden: 3 },
        { cvalue: '18', show: VueI18n.global.t('commont.age.a4'), iden: 4 }
    ],
    // 日期配置
    date: [
        { cvalue: '1,2,3,4,5', show: VueI18n.global.t('commont.date.d1') },
        { cvalue: '6,0', show: VueI18n.global.t('commont.date.d2') }
    ],
    // 时间配置
    time: [
        { cvalue: '00-12', show: VueI18n.global.t('commont.time.t1') },
        { cvalue: '12-18', show: VueI18n.global.t('commont.time.t2') },
        { cvalue: '18-24', show: VueI18n.global.t('commont.time.t3') }
    ]
};

// 时间格式二位数处理
const formatTimeTwo = (val) => {
    if (val === '') {
        return '';
    }

    if (Number(val) <= 10) {
        return '0' + val;
    }

    return val;
};

// 处理登录失败或者退出登录是否跳转到首页
const handleTokenErrorLink = () => {
    // 当在个人中心时，跳转到首页
    if (Router.currentRoute.value.name.indexOf('user-') !== -1) {
        Router.push({ name: 'home-index' });
    }
};

// 处理储存消息详情
const handleHisstoryMessageDetail = (status, item) => {
    if (status === 'set') {
        writeSessionData({ module: MESSAGE_DETAIL, data: item });
    } else if (status === 'get') {
        return getSessionData({ module: MESSAGE_DETAIL });
    } else {
        clearSessionData({ module: MESSAGE_DETAIL });
    }
};

// 处理储存ai搜索值
const handleAiSearchData = (status, item) => {
    if (status === 'set') {
        writeSessionData({ module: AI_SEARCH_HISTORRY, data: item });
    } else if (status === 'get') {
        return getSessionData({ module: AI_SEARCH_HISTORRY });
    } else {
        clearSessionData({ module: AI_SEARCH_HISTORRY });
    }
};

// 默认头像
const avatarDefault = (obj, filed) => {
    if (Object.prototype.toString.call(obj) !== '[object Object]') {
        return obj || '/static/images/d-avatar.png';
    }

    if (Object.keys(obj).length === 0) {
        return '/static/images/d-avatar.png';
    }

    return obj[filed] || '/static/images/d-avatar.png';
};

// 返回周几文本
const handleReturnWeekText = (val) => {
    let text = '';
    switch (Number(val)) {
        case 1:
            text = VueI18n.global.t('week.zhouyi');
            break;
        case 2:
            text = VueI18n.global.t('week.zhouer');
            break;
        case 3:
            text = VueI18n.global.t('week.zhousan');
            break;
        case 4:
            text = VueI18n.global.t('week.zhousi');
            break;
        case 5:
            text = VueI18n.global.t('week.zhouwu');
            break;
        case 6:
            text = VueI18n.global.t('week.zhouliu');
            break;
        default:
            text = VueI18n.global.t('week.zhouri'); 
            break;
    }

    return text;
};

const random = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
};

export {
    writeSessionData,
    getSessionData,
    clearSessionData,
    handleTokenErrorLink,
    formatTimeTwo,
    configOptions,
    handleHisstoryMessageDetail,
    handleAiSearchData,
    avatarDefault,
    handleReturnWeekText,
    random
}
