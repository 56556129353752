// 中文
export default {
    commont: {
        groupScheduleTip1: '該課程時段已預約完',
        lhq: '當前瀏覽器不支持sessionStorage,請勿使用無痕模式',
        age: {
            a1: '3-5歲',
            a2: '6-12歲',
            a3: '13-17歲',
            a4: '18歲或以上',
        },
        date: {
            d1: '平日：週一至週五',
            d2: '週末：週六至週日'
        },
        time: {
            t1: '中午12:00前',
            t2: '下午12:00 - 18:00',
            t3: '晚上18:00 - 24:00'
        },
        scheduleModel: {
            title: '授課時間',
            cs1: '預約課程',
            cs2: '顯示所有時間',
            cs3: '共',
            cs4: '節',
            cs5: '僅剩下',
            cs6: '個名額'
        }
    },
    productItem: {
        nianling: '年齡',
        fen: '分'
    },
    week: {
        zhouyi: '周一',
        zhouer: '周二',
        zhousan: '周三',
        zhousi: '周四',
        zhouwu: '周五',
        zhouliu: '周六',
        zhouri: '周日',
    },
    day: {
        shangwu: '上午',
        zhongwu: '中午',
        xiawu: '下午',
        wanshang: '晚上',
        shenye: '淩晨'
    },
    header: {
        login: '登錄',
        search: '搜索',
        moreLanguange: '多語言',
        user: '個人中心',
        tuichu: '退出',
        tongzhi: '通知'
    },
    // 登录弹窗
    loginModel: {
        youxiang: '郵箱',
        dengru: '登入',
        mima: '密碼',
        querenmima: '確認密碼',
        yanzhengma: '驗證碼',
        wangjimima: '忘記密碼',
        zhuce: '註冊',
        shangweizhuce: '尚未註冊？',
        chongzhimima: '重置密碼',
        fasong: '發送',
        queding: '確定',
        xingming: '姓名',
        tijiao: '提交'
    },
    // 留言弹窗
    lmModle: {
        title: '留言',
        fi1: '聯系方式',
        fi1p: '輸入聯系方式/號碼',
        fi2p: '寫上您想說的話，我們會及時回復您的信息',
        btn: '發送'
    },
    // ai弹窗
    ai: {
        t1: '留下您的需求',
        t2: '30秒內，AI會為您推薦最合適的課程',
        kaishi: '開始',
        yuyan1: '請問您想學什麼語言？',
        yuyan2: '告訴我們您想學習的語言',
        kecheng: '請問您想學什麼課程？',
        dengji: '選擇最符合自己情況的課程等級。',
        age1: '您目前幾歲呢？',
        age2: '選擇最符合自己情況的選項。',
        week: '請問您方便上課的日期是？',
        time: '請問您方便上課的時間段是？',
        shangyibu: '上一步',
        xiayibu: '下一步',
        seekText1: '正在為您尋找合適的課程',
        seekText2: '即將跳轉到搜尋頁'
    },
    // 首页
    index: {
        banner_t1: '讓您的學習暢快自由',
        banner_t2: '讓好奇心隨著您喜愛的主題課程和小組課程而瘋狂。',
        banner_t3: '流覽課程',
        shousuogengduokecheng: '搜索更多課程',
        reason_title: '4 個選擇 MRS碩研教室的理由',
        reason_btn: '開始挑選您的專屬教師吧！',
        product_title: '立即開啟您的優質課程之旅',
        wonder_title: '關於我們',
        gongsi_title: '公司評價'
    },
    // 底部
    footer: {
        t1: '加入MRS碩研教室大家庭，成為我們的精英老師',
        t2: 'Copyright © 2022 MRS Education保留最終解釋權',
        t3: '請把姓名，聯繫方式，個人簡歷，證書及youtube個人介紹視頻連結等資訊發送郵箱：mrs-edu@yandex.com',
        t4: '成為教師方式',
        t5: '確定',
        t6: '點擊郵箱按鈕，使用附件發送',
        t7: '聯系我們'
    },
    pdf: {
        shang: '上一頁',
        xia: '下一頁',
        da: '放大',
        xiao: '縮小',
        t1: '當前課件不存在'
    },
    // 个人中心
    user: {
        nodata: '暫無數據...',
        fanhui: '返回',
        index: {
            xiugaimima: '修改密碼',
            n1: '基本資料',
            n2: '我的金幣',
            n3: '我的訂單',
            n4: '我的收藏',
            n5: '我的消息'
        },
        info: {
            title: '個人信息',
            xingming: '姓名',
            touxiang: '頭像',
            xingbie: '性別',
            nan: '男',
            nv: '女',
            nianling: '年齡',
            youxiang: '郵箱',
            baocun: '保存',
            wechat: 'wechat聯系',
            whatsapp: 'whatsapp聯系'
        },
        collection: {
            title: '我的收藏',
            quxiao: '取消'
        },
        gold: {
            title: '我的金幣',
            shengyujinbi: '剩余金幣',
            chongzhi: '充值',
            zixunkefu: '咨詢客服',
            liushui: '我的帳單',
            jinbi: '金幣',
            xiaofei: '消費',
            saomazhifu: '掃碼支付/點選連結支付',
            sm: '說明：',
            cz1: '1、金幣按課程及地區的不同，分別折算成1元人民幣/港元/美元;',
            cz1_2: '2、不同地區，相同課程所顯示的金幣可能會不同;',
            cz2: '充值數量：',
            cz3: '輸入整數',
            cz4: '支付方式：',
            cz5: '支付金額：',
            cz6: '掃碼支付完後，請及時聯系客服或者留言，工作人員會第一時間處理。',
            zhifu: '支付'
        },
        order: {
            title: '我的訂單',
            n1: '全部',
            n2: '待付款',
            n3: '已付款',
            dingdanhao: '訂單號：',
            dingdanshijian: '訂單時間：',
            yizhifu: '已支付',
            daizhifu: '待支付',
            qushangke: '去上課',
            kejian: '課件',
            zuoye: '作業',
            pingjia: '評價',
            quzhifu: '去支付',
            xiangqing: '詳情',
            shangkeshijian: '上課時間：',
            f1: '鏈接復製成功，請在瀏覽器打開！',
            f2: '該瀏覽器不支持自動復製',
            b1: '當前課件不存在',
            detail: {
                d1: '距離開課: ',
                d2: '節',
                d3: '課程時間',
                d4: '操作',
            }
        },
        news: {
            title: '我的消息',
            n1: '全部',
            n2: '已讀',
            n3: '未讀',
            detailTitle: '消息',
            detailTitle1: '管理員/老師回復'
        },
        evaluate: {
            kechengpingjia: '課程評價',
            laoshipingjia: '老師評價',
            k1: '評價下您對這個課程內容',
            k2: '評價下您對老師的印象',
            tijiao: '提交',
            pj: '評價'
        },
        task: {
            t1: '作業文件',
            zuoye: '作業',
            shangchuan: '上傳',
            shuruneirong: '輸入內容',
            tijiao: '發送',
            d1: '第',
            d2: '節'
        }
    },
    // 列表
    list: {
        title: '查找課程',
        searchType: '類型',
        searchTag: '標簽',
        searchTime: '時間',
        searchAge: '年齡',
        sousuo: '搜索',
        chongzhi: '重置',
        liulangengduo: '瀏覽更多',
        shijianyi: '時間以',
        xianshi: '顯示',
        pinglun: '評論',
        sui: '歲',
        jie: '節',
        caozuochenggong: '操作成功'
    },
    // 详情
    detail: {
        p1: '該課程共',
        p2: '條評論',
        t1: '查看課程表',
        shoucang: '收藏',
        fenxiang: '分享',
        t2: '小組課程',
        t3: '一對一課程',
        i1: '分鐘',
        i2: '每節課',
        i3: '每週一次',
        i4: '共',
        i5: '節',
        i6: '歲',
        i7: '每班學習人數',
        i8: '起',
        z1: '自我介紹',
        z2: '瀏覽全部自我介紹',
        z3: '影片介紹',
        z4: '教師評論',
        z5: '顯示全部評價',
        wenti: {
            t: '常見問題',
            b1: '預約方式',
            c11: '購買課程後可依照教師行事曆的時間預約課程',
            c12: '如教師顯示『可即時授課』則可立即預約教師上課的時間，即時授課僅限英文口語課程',
            c13: '如管理員/教師沒在線上, 最快可預約 24 小時之後的課程',
            b2: '費用說明',
            c21: '體驗課程/評估課約為 30 分鐘',
            c22: '正式課程課時為30或45分鐘',
            c23: '正式課程購買超過 20 堂有額外折扣. 詳情請聯繫管理員（視老師開課價額而定）',
            b3: '上課說明',
            c31: '開課前 10 分鐘進入網站，選擇『我的課程』找到該節課並點選『進入教室』，即可開始上課，點擊連接語音和開啟鏡頭，與老師進行課堂互動',
            c32: '手機、平板電腦、電腦皆可使用（手機、平板請先下載上課軟體）',
            b4: '退課須知',
            c41: '當課程發生問題可選擇『回報問題』，可由管理員選擇重新預約或是退還金幣以下次使用',
            c42: '管理員將會在24小時內給予回復處理方式，繁忙時段可能需要2-3個工作天 '
        },
        bp1: '筆課程評價',
        bp2: '顯示全部評價',
        x1: '相似教師推薦',
        orderTip: '選擇授課時間購買對應課',
        copyTip1: '復製成功，請在瀏覽器打開！',
        copyTip2: '該瀏覽器不支持自動復製',
        noDeatilDataTip: '暫無詳情數據'
    },
    // 校验提示
    checkTip: {
        // 留言弹窗
        lmm: {
            t1: '請輸入聯系方式',
            t2: '請輸入留言內容',
            t3: '留言成功!'
        },
        // 登录窗口
        loginModel: {
            youxiang: '郵箱不能為空',
            mima: '密碼不能為空',
            querenmima: '確認密碼不能為空',
            mimayiyang: '兩次密碼不一樣',
            yanzhengma: '驗證碼不能為空',
            xingming: '姓名不能為空',
            dengluchenggong: '登錄成功',
            xiugaimimachenggong: '密碼修改成功！',
            zhucechenggong: '註冊成功！',
            fasongyanzhengma: '驗證碼已發送到郵箱，請註意查找',
            tuichudenglu: '是否確定退出登錄？',
            queding: '確定',
            tuichuchenggong: '退出登錄成功'
        },
        // 个人中心-评价
        evaluate: {
            kecheng: '對這個課程內容的評價',
            laoshi: '評價您對老師的印象',
            t1: '請選擇課程評分',
            t2: '課程評價不低於10個字',
            t3: '請選擇老師評分',
            t4: '老師內容不低於10個字',
            success: '評價成功！'
        },
        userinfo: {
            xingming: '請輸入姓名',
            nianling: '請輸入正確的年齡',
            tupiandaxiao: '上傳圖片大小不能超過',
            tupianshibai: '上傳失敗！',
            gengxinchenggong: '更新成功！',
            wechat: '請輸入wechat賬號',
            whatsapp: '請輸入whatsapp賬號'
        },
        // 个人中心-作业
        usertask: {
            nr: '可輸入作業描述內容',
            tupiandaxiao: '上傳文件大小不能超過',
            tupianshibai: '上傳失敗！'
        },
        // 下单
        orderModel: {
            time1: '新增時間必須大於最後一堂課時間',
            time2: '請添加當前套餐剩余上課時間',
            tianjia: '當前時間已添加過',
            xuanzetaocan: '請選擇套餐',
            tip1: '當前賬號剩余金幣不夠支付選中的套餐，是否立即跳轉到金幣充值頁',
            queding: '確定',
            quxiao: '取消',
            success: '購買課程成功'
        },
        // 金币
        gold: {
            t1: '請選擇支付方式'
        }
    }
}