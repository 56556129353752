// 中文
export default {
    commont: {
        groupScheduleTip1: '该课程时段已预约完',
        lhq: '当前浏览器不支持sessionStorage,请勿使用无痕模式',
        age: {
            a1: '3-5岁',
            a2: '6-12岁',
            a3: '13-17岁',
            a4: '18岁或以上',
        },
        date: {
            d1: '平日：周一至周五',
            d2: '周末：周六至周日'
        },
        time: {
            t1: '中午12:00前',
            t2: '下午12:00 - 18:00',
            t3: '晚上18:00 - 24:00'
        },
        scheduleModel: {
            title: '授课时间',
            cs1: '预约课程',
            cs2: '显示所有时间',
            cs3: '共',
            cs4: '节',
            cs5: '仅剩下',
            cs6: '个名额'
        }
    },
    productItem: {
        nianling: '年龄',
        fen: '分'
    },
    week: {
        zhouyi: '周一',
        zhouer: '周二',
        zhousan: '周三',
        zhousi: '周四',
        zhouwu: '周五',
        zhouliu: '周六',
        zhouri: '周日',
    },
    day: {
        shangwu: '上午',
        zhongwu: '中午',
        xiawu: '下午',
        wanshang: '晚上',
        shenye: '凌晨'
    },
    header: {
        login: '登录',
        search: '搜索',
        moreLanguange: '多语言',
        user: '个人中心',
        tuichu: '退出',
        tongzhi: '通知'
    },
    // 登录弹窗
    loginModel: {
        youxiang: '邮箱',
        dengru: '登入',
        mima: '密码',
        querenmima: '确认密码',
        yanzhengma: '验证码',
        wangjimima: '忘记密码',
        zhuce: '注册',
        shangweizhuce: '尚未注册？',
        chongzhimima: '重置密码',
        fasong: '发送',
        queding: '确定',
        xingming: '姓名',
        tijiao: '提交'
    },
    // 留言弹窗
    lmModle: {
        title: '留言',
        fi1: '联系方式',
        fi1p: '输入联系方式/号码',
        fi2p: '请写上您的留言，我们会及时回复您的信息',
        btn: '发送'
    },
    // ai弹窗
    ai: {
        t1: '留下您的需求',
        t2: '30秒内，AI会为您推荐最合适的课程',
        kaishi: '开始',
        yuyan1: '请问您想学什么语言？',
        yuyan2: '告诉我们您想学习的语言',
        kecheng: '请问您想学什么课程？',
        dengji: '选择最符合自己情况的课程等级。',
        age1: '您目前几岁呢？',
        age2: '选择最符合自己情况的选项。',
        week: '请问您方便上课的日期是？',
        time: '请问您方便上课的时间段是？',
        shangyibu: '上一步',
        xiayibu: '下一步',
        seekText1: '正在为您寻找合适的课程',
        seekText2: '即将跳转到搜索页'
    },
    // 首页
    index: {
        banner_t1: '让您的学习畅快自由',
        banner_t2: '让好奇心随着您喜爱的主题课程和小组课程而疯狂',
        banner_t3: '浏览课程',
        shousuogengduokecheng: '搜索更多课程',
        reason_title: '4 个选择 MRS硕研教室的理由',
        // reason_c1: '任选上课时间',
        // reason_c2: '自己在课表选择上课时间',
        // reason_c3: '方便学习没有压力',
        // reason_c4: '专属你的课程',
        // reason_c5: '依照能力、需求、喜好',
        // reason_c6: '为你打造客制化 有效进步的课程',
        // reason_c7: '1 堂也能买',
        // reason_c8: '没有业务绑约 根据个人需求',
        // reason_c9: '1、5、10 堂都能购买',
        // reason_c10: '自选上课老师',
        // reason_c11: '✔ 影片 ✔ 履历 ✔ 学生评价',
        // reason_c12: '3大方向自选老师 进步看得见',
        reason_btn: '开始挑选您的专属教师吧！',
        product_title: '立即开启您的优质课程之旅',
        wonder_title: '关于我们',
        gongsi_title: '公司评价'
    },
    // 底部
    footer: {
        t1: '加入MRS硕研教室大家庭，成为我们的精英老师',
        t2: 'Copyright © 2022 MRS Education保留最终解释权',
        t3: '请把姓名，联系方式，个人简历，证书及youtube个人介绍视频链接等信息发送邮箱：mrs-edu@yandex.com',
        t4: '成为教师方式',
        t5: '确定',
        t6: '点击邮箱按钮，使用附件发送 ',
        t7: '联系我们'
    },
    pdf: {
        shang: '上一页',
        xia: '下一页',
        da: '放大',
        xiao: '缩小',
        t1: '当前课件不存在'
    },
    // 个人中心
    user: {
        nodata: '暂无数据...',
        fanhui: '返回',
        index: {
            xiugaimima: '修改密码',
            n1: '基本资料',
            n2: '我的金币',
            n3: '我的订单',
            n4: '我的收藏',
            n5: '我的消息'
        },
        info: {
            title: '个人信息',
            xingming: '姓名',
            touxiang: '头像',
            xingbie: '性别',
            nan: '男',
            nv: '女',
            nianling: '年龄',
            youxiang: '邮箱',
            baocun: '保存',
            wechat: 'Wechat联系',
            whatsapp: 'What’s app联系'
        },
        collection: {
            title: '我的收藏',
            quxiao: '取消'
        },
        gold: {
            title: '我的金币',
            shengyujinbi: '剩余金币',
            chongzhi: '充值',
            zixunkefu: '咨询客服',
            liushui: '我的账单',
            jinbi: '金币',
            xiaofei: '消费',
            saomazhifu: '扫码支付',
            sm: '说明：',
            cz1: '1、金币按课程及地区的不同，分别折算成1元人民币/港元/美元;',
            cz1_2: '2、不同地区，相同课程所显示的金币可能会不同;',
            cz2: '充值数量：',
            cz3: '输入币值(整数)',
            cz4: '支付方式：',
            cz5: '支付金额：',
            cz6: '扫码支付完成后，请及时联系客服或者留言，工作人员将会第一时间处理。',
            zhifu: '支付'
        },
        order: {
            title: '我的订单',
            n1: '全部',
            n2: '待付款',
            n3: '已付款',
            dingdanhao: '订单号：',
            dingdanshijian: '订单时间：',
            yizhifu: '已支付',
            daizhifu: '待支付',
            qushangke: '去上课',
            kejian: '课件',
            zuoye: '作业',
            pingjia: '评价',
            quzhifu: '去支付',
            xiangqing: '详情',
            shangkeshijian: '上课时间：',
            f1: '链接复制成功，请在浏览器打开！',
            f2: '该浏览器不支持自动复制',
            b1: '当前课件不存在',
            detail: {
                d1: '距离开课: ',
                d2: '节',
                d3: '课程时间',
                d4: '操作',
            }
        },
        news: {
            title: '我的消息',
            n1: '全部',
            n2: '已读',
            n3: '未读',
            detailTitle: '消息',
            detailTitle1: '管理员/老师回复'
        },
        evaluate: {
            kechengpingjia: '课程评价',
            laoshipingjia: '老师评价',
            k1: '对课程内容的评价',
            k2: '对老师的评价',
            tijiao: '提交',
            pj: '评价'
        },
        task: {
            t1: '作业文件',
            zuoye: '作业',
            shangchuan: '上传',
            shuruneirong: '输入内容',
            tijiao: '发送',
            d1: '第',
            d2: '节'
        }
    },
    // 列表
    list: {
        title: '查找课程',
        searchType: '类型',
        searchTag: '标签',
        searchTime: '时间',
        searchAge: '年龄',
        sousuo: '搜索',
        chongzhi: '重置',
        liulangengduo: '浏览更多',
        shijianyi: '时间以',
        xianshi: '显示',
        pinglun: '评论',
        sui: '岁',
        jie: '节',
        caozuochenggong: '操作成功'
    },
    // 详情
    detail: {
        p1: '该课程共',
        p2: '条评论',
        t1: '查看课程表',
        shoucang: '收藏',
        fenxiang: '分享',
        t2: '小组课程',
        t3: '一对一课程',
        i1: '分钟',
        i2: '每节课',
        i3: '每周一次',
        i4: '共',
        i5: '节',
        i6: '岁',
        i7: '每班学习人数',
        i8: '起',
        z1: '自我介绍',
        z2: '浏览全部自我介绍',
        z3: '影片介绍',
        z4: '教师评论',
        z5: '显示全部评价',
        wenti: {
            t: '常见问题',
            b1: '预约方式',
            c11: '购买课程后可依照教师行事历的时间预约课程',
            c12: '如教师显示『可实时授课』则可立即预约教师上课的时间,实时授课仅限英文口语课程',
            c13: '如管理员/教师没在线上, 最快可预约 24 小时之后的课程',
            b2: '费用说明',
            c21: '体验课程/评估课约为 30 分钟',
            c22: '正式课程课时为30或45分钟',
            c23: '正式课程购买超过 20 堂有额外折扣,详情请联系管理员（视老师开课价额而定）',
            b3: '上课说明',
            c31: '开课前 10 分钟进入网站，选择『我的课程』找到该节课并点选『进入教室』，即可开始上课,点击连接语音和开启镜头，与老师进行课堂互动',
            c32: '手机、平板电脑、计算机皆可使用（手机、平板请先下载上课软件）',
            b4: '退课须知',
            c41: '当课程发生问题可选择『回报问题』，可由管理员选择重新预约或是退还金币以下次使用',
            c42: '管理员将会在24小时内给予回复处理方式，繁忙时段可能需要2-3个工作天 '
        },
        bp1: '笔名字课程评价',
        bp2: '显示全部评价',
        x1: '相似教师推荐',
        orderTip: '选择授课时间购买对应课程',
        copyTip1: '复制成功，请在浏览器打开！',
        copyTip2: '该浏览器不支持自动复制',
        noDeatilDataTip: '暂无详情数据'
    },
    // 校验提示
    checkTip: {
        // 留言弹窗
        lmm: {
            t1: '请输入联系方式',
            t2: '请输入留言内容',
            t3: '留言成功!'
        },
        // 登录窗口
        loginModel: {
            youxiang: '邮箱不能为空',
            mima: '密码不能为空',
            querenmima: '确认密码不能为空',
            mimayiyang: '两次密码不一样',
            yanzhengma: '验证码不能为空',
            xingming: '姓名不能为空',
            dengluchenggong: '登录成功',
            xiugaimimachenggong: '密码修改成功！',
            zhucechenggong: '注册成功！',
            fasongyanzhengma: '验证码已发送到邮箱，请注意查找',
            tuichudenglu: '是否确定退出登录？',
            queding: '确定',
            tuichuchenggong: '退出登录成功'
        },
        // 个人中心-评价
        evaluate: {
            kecheng: '对这个课程内容的评价',
            laoshi: '评价您对老师的印象',
            t1: '请选择课程评分',
            t2: '课程评价不低于10个字',
            t3: '请选择老师评分',
            t4: '内容不低于10个字',
            success: '评价成功！'
        },
        userinfo: {
            xingming: '请输入姓名',
            nianling: '请输入正确的年龄',
            tupiandaxiao: '上传图片大小不能超过',
            tupianshibai: '上传失败！',
            gengxinchenggong: '更新成功！',
            wechat: '请输入Wechat账号',
            whatsapp: '请输入What’s app账号'
        },
        // 个人中心-作业
        usertask: {
            nr: '可输入作业描述内容',
            tupiandaxiao: '上传文件大小不能超过',
            tupianshibai: '上传失败！'
        },
        // 下单
        orderModel: {
            time1: '新增时间必须大于最后一节课的时间',
            time2: '请添加当前套餐剩余的上课时间',
            tianjia: '当前时间已曾添加',
            xuanzetaocan: '请选择套餐',
            tip1: '当前账号剩余金币不够支付选中的套餐，是否立即跳转到金币充值页',
            queding: '确定',
            quxiao: '取消',
            success: '购买课程成功'
        },
        // 金币
        gold: {
            t1: '请选择支付方式'
        }
    }
}